<template>
  <div id="videoContent">
    <div class="major-body-fix">
      <div class="main">
        <div class="video-main">
          <video-player
            class="video-player vjs-custom-skin"
            id="media"
            ref="videoPlayer"
            :playsinline="true"
            style="object-fit: fill"
            :options="playerOptions"
            :x5-video-player-fullscreen="true"
          ></video-player>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import { videoPlayer } from 'vue-video-player'
export default {
  name: 'Video',
  components: {
    videoPlayer,
  },
  props: {
    fullScreen: {
      type: Boolean,
      default: false,
    },
    videoUrl: {
      type: String,
      default: '',
    },
    coverUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      playerOptions: {
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4',
            src: '',
          },
        ],
        poster: '',
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: false, //全屏按钮
        },
      },
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    },
  },
  watch: {
    videoUrl: {
      handler(newval) {
        if (this.coverUrl) this.playerOptions.poster = this.coverUrl
        this.playerOptions.sources[0].src = newval
      },
    },
  },

  created() {
    if (this.coverUrl) this.playerOptions.poster = this.coverUrl
    this.playerOptions.sources[0].src = this.videoUrl
  },
}
</script>
<style lang="less">
#videoContent {
  .major-body-fix {
    margin: 0;
    .main {
      position: relative;
      height: 195px;
    }
    .video-main {
      width: 100%;
      height: 100%;
      border-radius: 15px;
      overflow: hidden;
      #media {
        object-fit: fill; /* 解决视频出现黑边*/
        margin: 0 auto;
        height: 100%;
        overflow: hidden;
        border-radius: 15px;
        .vjs-poster {
          background-size: cover;
          border-radius: 15px;
          .vjs-big-play-button {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
  .vjs-custom-skin > .video-js .vjs-big-play-button {
    width: 50px;
    height: 50px !important;
    line-height: 50px !important;
    border-radius: 50%;
    margin-left: -25px;
  }
  .video-js.vjs-fluid {
    height: 100%;
    border-radius: 15px;
  }
}
</style>
