import request from '@/utils/http'

const resultApi = {
  resultfield: 'api/wechat/assessment/subject/fieldScore',
}
// 获取结果信息
export function getResult(parameter) {
  return request({
    url: resultApi.resultfield,
    method: 'get',
    params: parameter,
  })
}
