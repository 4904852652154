<template>
  <div class="result">
    <!-- 浮动弹窗 -->
    <div
      class="result-qrcode"
      :style="{ width: isshowQrcode ? '124px' : '38px' }"
      @click="isshowQrcode = !isshowQrcode"
    >
      <div class="qrcode-info" v-show="!isshowQrcode">点击咨询</div>
      <div class="qrcode-img" v-show="isshowQrcode">
        <img src="@/assets/image/ico_qrcode.jpg" />
        <p>添加顾问&nbsp;解读报告</p>
      </div>
    </div>
    <!-- 顶部导航栏 -->
    <div class="result-navbar">
      <p class="navbar-title">评估结果</p>
    </div>

    <div class="content">
      <div class="top">
        <img class="display-image" src="@/assets/image/bg_result.png" alt="" />
        <div class="description">
          <p>评估结果</p>
          <p>Evaluation Result</p>
        </div>
      </div>

      <div class="content-container">
        <div class="list">
          <div class="title">
            <img src="@/assets/image/ico_resultitem.png" alt="" />
            <p>得分情况</p>
          </div>
          <div class="content-container-box">
            <div class="score-box">
              <div class="field">
                <p class="field-name">{{ field.name }}</p>
                <p class="score">
                  此次得分 <span>{{ field.score }}</span> 分
                </p>
                <p class="rate">
                  得分率 <span>{{ field.successRate }}%</span>
                </p>
              </div>

              <div class="child-field">
                <div class="field-box" v-for="(item, index) in secondField" :key="index">
                  <p class="item-name">{{ item.name }}</p>
                  <p class="score">
                    此次得分
                    <span :class="item.successRate > field.successRate ? 'orange' : ''">{{ item.score }}</span>
                    分
                  </p>
                  <p class="rate">
                    得分率
                    <span :class="item.successRate > field.successRate ? 'orange' : ''">{{ item.successRate }}%</span>
                  </p>
                </div>
                <div v-if="childField.length > 3">
                  <div class="more" @click="getmore()" v-if="isShowMore">
                    <div>查看更多</div>
                    <img src="@/assets/image/ico_arrow_down.png" alt="" />
                  </div>
                  <div class="more" @click="getput()" v-else>
                    <div>收起</div>
                    <img src="@/assets/image/ico_arrow_up.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="title">
            <img src="@/assets/image/ico_resultitem.png" alt="" />
            <p>表现情况</p>
          </div>
          <div class="content-container-box">
            <div class="performance" v-for="(item, index) in childField" :key="index">
              <p>{{ item.name }}</p>
              <van-progress
                :percentage="item.successRate ? item.successRate : 0"
                :show-pivot="false"
                :stroke-width="8"
                color="#4563ee"
              />
            </div>
          </div>
        </div>
        <div class="list">
          <div class="title">
            <img src="@/assets/image/ico_resultitem.png" alt="" />
            <p>提升目标</p>
          </div>
          <div class="content-container-box">
            <div class="target">
              <div class="video" v-if="isShowVideo">
                <resultVideo :videoUrl="videoUrl"></resultVideo>
              </div>
              <p>{{ field.introduction }}</p>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="title" style="margin: 0">
            <img src="@/assets/image/ico_resultitem.png" alt="" />
            <p>提升计划</p>
          </div>
          <div class="content-container-box">
            <div class="planning">
              <van-tabs swipe-threshold="3" title-active-color="#3350C7" color="#3350C7" line-width="20px">
                <van-tab v-for="(item, index) in childField" :key="index" :title="item.name">
                  <p>{{ item.introduction }}</p>
                  <div class="no-package" v-if="item.appEvaluationFieldSkillsVOList.length == '0'">
                    暂无推荐，正在研发中
                  </div>
                  <div
                    class="package"
                    v-else
                    v-for="(items, indexs) in item.appEvaluationFieldSkillsVOList"
                    :key="indexs"
                  >
                    <div class="name">{{ items.name }}</div>
                    <p>{{ items.description }}</p>
                  </div>
                </van-tab>
              </van-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="result-button" @click="goLink()">结束评估，查看提升方案</div>
  </div>
</template>

<script>
import resultVideo from '@/components/ResultVideo'
import { getResult } from '@/api/result'

export default {
  name: 'Result',
  components: {
    resultVideo,
  },
  data() {
    return {
      isshowQrcode: false,
      field: [], // 得分情况
      childField: [], // 表现情况
      secondField: [],
      isShowMore: true, // 查看更多
      isShowVideo: false,
      videoUrl: '',
    }
  },
  created() {
    this.evaluationResult()
  },
  methods: {
    // 获取评估结果
    evaluationResult() {
      getResult({ mainFieldCode: this.$route.query.fieldid }).then(res => {
        if (res.code == 0) {
          this.field = res.data.domain
          this.childField = res.data.subField
          this.secondField = this.childField.slice(0, 3)
          if (this.field.url) {
            const fileName = this.field.url.lastIndexOf('.') //取到文件名开始到最后一个点的长度
            const fileNameLength = this.field.url.length //取到文件名长度
            const fileFormat = this.field.url.substring(fileName + 1, fileNameLength)
            if (fileFormat == 'mp4') {
              this.isShowVideo = true
              this.videoUrl = this.field.url
            }
          }
        }
      })
    },
    //查看更多
    getmore() {
      this.isShowMore = false
      let score = []
      score = this.childField.slice(3)
      this.secondField = this.secondField.concat(score)
    },
    //收起
    getput() {
      this.isShowMore = true
      this.secondField = this.secondField.slice(0, 3)
    },
    // 打开外链
    goLink() {
      window.location.href =
        'https://appixgujdjj6027.h5.xiaoeknow.com/mp_more/eyJpZCI6IjU1MTc4MCIsImNoYW5uZWxfaWQiOiIiLCJjb21wb25lbnRfaWQiOjI5MzU3MDR9'
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.result {
  margin-bottom: 68px;
}
.result-qrcode {
  z-index: 99;
  position: fixed;
  top: 285px;
  right: 0;
  height: 112px;
  background: #ffa73e;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px 0px 0px 15px;
  transition: width 0.3s ease 0s;

  .qrcode-info {
    width: 38px;
    margin: 20px 12px;
    writing-mode: vertical-lr;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 100%;
  }

  .qrcode-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 114px;
    margin: 0 4px;

    img {
      width: 60px;
      height: 60px;
      max-width: 100%;
      margin: 14px 26px 8px;
    }
    p {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
}

.result-navbar {
  position: relative;
  width: 100%;
  height: 44px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  .navbar-title {
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 44px;
    text-align: center;
    line-height: 44px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8);
  }
}

.result {
  .content {
    margin: -44px 0 0;
    .top {
      position: relative;
      overflow: hidden;
      .display-image {
        width: 375px;
        height: 213px;
      }
      .description {
        position: absolute;
        top: 100px;
        left: 28px;
        p {
          margin: 0;
          color: #ffffff;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
        p:last-child {
          margin: 6px 0 0;
          font-size: 16px;
          font-family: Helvetica;
          color: #ffffff;
        }
      }
    }
    .content-container {
      border-radius: 20px;
      margin-top: -20px;
      background: #ffffff;
      position: relative;
      padding: 20px 16px;
      .list {
        margin-bottom: 20px;
        .title {
          display: flex;
          align-items: center;
          margin-bottom: 18px;
          img {
            width: 25px;
            height: 25px;
            margin-right: 15px;
          }
          p {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
          }
        }
        .content-container-box {
          .score-box {
            .field {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 0 12px;
              padding: 0 10px;
              .field-name {
                width: 25%;
                font-size: 14px;
              }
              .rate {
                font-weight: 600;
              }
              .score,
              .rate {
                font-weight: 600;
                font-size: 14px;
              }
            }
            p {
              font-size: 14px;
              font-weight: 600;
              span {
                font-size: 16px;
              }
            }
            .score {
              opacity: 0.8;
              margin-top: -5px;
              font-weight: normal;
              span {
                color: #4563ee;
              }
            }
            .rate {
              font-weight: normal;
              opacity: 0.8;
              margin-top: -5px;
              span {
                color: #4563ee;
              }
            }
            .child-field {
              background: #f8f8f8;
              padding: 12px;
              margin-top: 12px;
              border-radius: 8px;
              .field-box {
                height: 60px;
                background: #ffffff;
                border-radius: 8px;
                padding: 0 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 8px;
                .item-name {
                  width: 25%;
                }
                span {
                  font-size: 16px;
                  color: #4563ee;
                  display: inline-block;
                }
                .score span {
                  width: 16px;
                  text-align: center;
                }
                .orange {
                  color: #ff783e;
                }
              }
            }
          }
          .more {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 12px;
            div {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #000000;
            }
            img {
              width: 8px;
              margin-left: 7px;
            }
          }
          .performance {
            width: 100%;
            height: 20px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 12px;
            font-size: 14px;
            opacity: 0.8;
            p {
              width: 100px;
            }
            .van-progress {
              width: calc(100% - 100px);
            }
          }
          .target {
            z-index: 10;
            .video {
              width: 100%;
              height: 193px;
              background: #f5f5f5;
            }
            p {
              font-size: 12px;
              opacity: 0.4;
              margin-top: 12px;
            }
          }
          .planning {
            .van-tabs__nav--complete {
              padding-left: 0;
              padding-right: 0;
            }
            p {
              font-size: 12px;
              opacity: 0.4;
              margin-top: 16px;
            }
            .name {
              font-size: 15px;
              font-weight: 600;
              margin-top: 20px;
              opacity: 0.9;
            }
            .no-package {
              font-size: 15px;
              font-weight: 600;
              text-align: center;
              margin-top: 40px;
              opacity: 0.6;
            }
          }
        }
      }
    }
  }
}
.result-button {
  z-index: 15;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 68px;
  margin: 0px 0 0;
  background: #4760c6;
  opacity: 0.96;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 68px;
}
</style>
